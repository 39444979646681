export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'ja',
  messages: {
    ja: {
      siteName: "熊野暮らしと全国ぶらり旅",
      caption: {
        top: "トップ",
        postList: "投稿一覧",
        postSeriesList: "連載一覧",
        journalList: "旅の記録",
        visitHistory: "訪問履歴",
        travelGuideList: "旅のしおり",
        areaMapList: "エリア一覧",
        placeList: "場所一覧",
        placeListOfArea: "%{areaName}の場所一覧",
        readMoreVideos: "動画をもっと見る",
        readMorePosts: "投稿をもっと見る",
        readMorePostSeries: "連載をもっと見る",
        readMoreJournals: "旅の記録をもっと見る",
        readMorePhotos: "写真をもっと見る",
        backToTop: "トップへ戻る",
        shareThisPhoto: "この写真をシェアする",
        aboutUsingPhoto: "写真の利用について",
        copyThisLink: "リンクをコピー",
        copied: "コピーしました",
        tapToEnlarge: "タップして拡大",
        profile: "プロフィール",
        album: "写真館",
        name: "名前",
        backToAreaTravelGuide: "{area}の旅のしおりへ戻る",
        openGoogleMap: "Googleマップで開く",
        cookiePolicy: "クッキーポリシー",
      },
      message: {
        pageNotFound: "ここはどこ？",
        pageErrorOccurred: "何かやらかしたみたい・・・",
        copyFailed: "コピーに失敗しました。ブラウザがサポートしていないかもしれません。",
        albumDescription: "熊野や日本各地の旅先で撮った写真です。",
        youCanRentBikeInFrontOfStation: "駅前の観光協会で自転車を借りると移動が楽ですよ！",
        openMapAppWhenTapMap: "地図をタップするとアプリが開きます",
      },
      photo: {
        cameraName: "カメラ",
        cameraMaker: "メーカー",
        takenOn: "撮影日",
        iso: "ISO",
        focalLength: "焦点距離",
        focalLengthIn35mmFormat: "焦点距離（35mm換算）",
        aperture: "F値",
        exposureTime: "露出時間",
      },
      area: {
        nankiKumano: "南紀熊野",
        kumanoshi: "熊野市",
        shingushi: "新宮市",
        kochishi: "高知市",
      },
    },
    en: {
      siteName: "Kumano Life, Travel in Japan",
      caption: {
        top: "Top",
        postList: "Post List",
        postSeriesList: "Post Series List",
        journalList: "Journal List",
        visitHistory: "Visit History",
        travelGuideList: "Travel Guide in Japan",
        areaMapList: "Area List",
        placeListOfArea: "Place List in %{areaName}",
        placeList: "Place List",
        readMoreVideos: "Read more videos",
        readMorePosts: "Read more posts",
        readMorePostSeries: "Read more post series",
        readMoreJournals: "Read more journals",
        readMorePhotos: "Read more photos",
        backToTop: "Back to top",
        shareThisPhoto: "Share this photo",
        aboutUsingPhoto: "About using photo",
        copyThisLink: "Copy this link",
        copied: "Copied",
        tapToEnlarge: "Tap to enlarge",
        profile: "Profile",
        album: "Album",
        name: "Name",
        backToAreaTravelGuide: `Back to {area} Travel Guide`,
        openGoogleMap: "Open Google Map",
        cookiePolicy: "Cookie Policy",
      },
      message: {
        pageNotFound: "This page is not found.",
        pageErrorOccurred: "Something happened.",
        copyFailed: "It seems that the copy function may not be supported by your browser.",
        albumDescription: "These are photos taken during my travels in Kumano and various other parts of Japan",
        youCanRentBikeInFrontOfStation: "You can rent a bicycle at the tourist association in front of the station, which makes it easier to get around!",
        openMapAppWhenTapMap: "Tap the map to open the app",
      },
      photo: {
        cameraName: "Camera",
        cameraMaker: "Camera Manufacturer",
        takenOn: "Taken on",
        iso: "ISO",
        focalLength: "Focal Length",
        focalLengthIn35mmFormat: "Focal Length (35mm format)",
        aperture: "Aperture",
        exposureTime: "Exposure Time",
      },
      area: {
        nankiKumano: "Nanki Kumano",
        kumanoshi: "Kumano City",
        shingushi: "Shingu City",
        kochi: "Kochi City",
      },
    }
  }
}))